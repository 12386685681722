import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardState } from '../../providers/state/dashboard.state';
import {Settings} from '../../app.settings.model';
import {AppSettings} from '../../app.settings';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

  public router:Router;
  public settings: Settings;

  constructor(router:Router, public dashboardState:DashboardState, public appSettings:AppSettings) {
      this.router = router;
      this.dashboardState.step = 0;
      this.settings = this.appSettings.settings;
  }

  ngOnInit() {  }

  calculate() {
      this.router.navigate(['/calculadora'], {skipLocationChange: true});
  }

}
