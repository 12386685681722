import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { routing } from './app.routing';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpHandler } from "@angular/common/http";
import { ToastrModule } from 'ngx-toastr';
import { Ng5SliderModule } from 'ng5-slider';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppSettings } from './app.settings';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { HeaderComponent } from './theme/components/header/header.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { SidebarComponent } from './theme/components/sidebar/sidebar.component';
import { BackTopComponent } from './theme/components/back-top/back-top.component';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';

import { SavingsProvider } from './providers/savings/savings'
import { AuthInterceptorProvider } from './providers/auth-interceptor/auth-interceptor';
import { DashboardState } from './providers/state/dashboard.state';


@NgModule({  
  imports: [
    BrowserModule,
    PerfectScrollbarModule,
    routing,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    Ng5SliderModule,
    NgbModule,
    FormsModule,
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    BackTopComponent,
    HomeComponent,
    NotFoundComponent,
  ],
  providers: [ 
    AppSettings,
    SavingsProvider,
    DashboardState,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorProvider, multi: true },
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
