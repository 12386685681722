export class Settings {
    constructor(public name: string,
                public title: string,
                public theme: {
                    navbarIsFixed: boolean,
                    footerIsFixed: boolean,
                    sidebarIsFixed: boolean,
                    skin:string,
                },
                public server: {
                    googleMapsAPIKey: string,
                    feature_googleMaps: boolean,
                    googleAnalytics: string,
                    feature_googleAnalytics: boolean,
                    googleTagManager: string,
                    feature_googleTagManager: boolean,
                    feature_preview_dialog: boolean,
                    protocol: string,
                    host: string,
                    port: number,
                    calculatorEndpoint: string,
                    simplifiedEndpoint: string,
                    loginEndpoint: string,
                    tosURL: string,
                },
                public client: {
                    id: string,
                    name: string,
                    email: string,
                    web: string,
                    logo: string,
                    instagram:string,
                    twitter: string,
                    youtube: string,
                    linkedin:string,
                    facebook:string,
                    privacyPolicies: string
                    backgroundPhoto: string,
                    buttonStyle: string,
                    fontFamily: string,
                    navbarLogoDimension: string,
                    showHeader: number,
                    showFooter: number,
                    welcome_text: string,
                    promotion_text: string,
                    telephone_required: number,
                    name_required: number,
                    cups_required: number,
                    allow_login: number,
                    inital_map_latitude: number,
                    inital_map_longitude: number,
                    investment_renting: number,
                    simplified_mode: boolean,
                    home_costs: Array<number>,
                    company_costs: Array<number>,
                    feature_installation_costs: boolean,
                    feature_cost_saving_percent: boolean
                }
                ) {

    }
}
