import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppSettings } from './app/app.settings';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


let { settings: { server, client } } = new AppSettings()

// Title
document.querySelector('title').innerHTML = client.name;

// Global font
if (client.fontFamily) {
  document.write(`<link rel="stylesheet" href="assets/fonts/${client.fontFamily}.ttf"></link>`);
  document.body.setAttribute('style', `font-family: ${client.fontFamily}, Sans-serif !important`);
}

// Logo dimensions
onload = () => client.navbarLogoDimension &&
  document.querySelector('.logo').setAttribute('style', `${client.navbarLogoDimension}`)

// Google features and codes
let { 
  googleMapsAPIKey,
  feature_googleMaps, 
  googleAnalytics,
  feature_googleAnalytics,
  googleTagManager,
  feature_googleTagManager 
} = server

// Map
if (feature_googleMaps && !client.simplified_mode)
document.write(`<script src="https://maps.googleapis.com/maps/api/js?key=${googleMapsAPIKey}&libraries=places"></script>`);

// Google Analytics
if (feature_googleAnalytics) {
  document.write(`<script async src="https://www.googletagmanager.com/gtag/js?id=${googleAnalytics}"></script>`)
  document.write(`<script async>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${googleAnalytics}');
  </script>`);
}

// Google Tag Manager
if (feature_googleTagManager)
  document.write(`<script async>
    (function(w,d,s,l,i){
      w[l] = w[l] || [];
      w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});

      var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l!='dataLayer'?'&l='+l:'';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${googleTagManager}');
  </script>`);
