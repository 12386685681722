import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { PagesComponent } from './pages/pages.component';
import { HomeComponent } from './pages/home/home.component';

export const routes: Routes = [
  {
    path: '', 
    component: PagesComponent, 
    children:[
      { path: '', component: HomeComponent, data: { } },
      { path: 'calculadora', loadChildren: './pages/dashboard/dashboard.module#DashboardModule', data: { }  },
    ]
  },
  { path: '**', redirectTo: '' }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
});