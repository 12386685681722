import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Response } from "../../interfaces/response.model";
import { Saving } from "../../interfaces/saving.model";
import { PostSaving } from "../../interfaces/postSaving.model";
import { PostSSC } from "../../interfaces/postSSC.model";

import { AppSettings } from '../../app.settings';
import { Settings } from '../../app.settings.model';

/*
  Generated class for the MapProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class SavingsProvider {

  public settings: Settings;
  constructor(private httpClient:HttpClient, public appSettings:AppSettings) {
      this.settings = this.appSettings.settings;
  }


    login(username:string, password:string):Promise<string>{
        return new Promise((resolve, reject) => {
            let data = {
                username: username,
                password: password
            }
            this.httpClient.post(this.settings.server.loginEndpoint, data).subscribe((res) => {
                console.log(res);
                resolve(res['assessorId']);
            }, (error) => {
                reject();
            });
        });
    }

    postSavings(postSaving:PostSaving):Promise<Saving>{
        return new Promise((resolve, reject) => {
            console.log('Datos a enviar: ', postSaving);
            this.httpClient.post<Saving>(this.settings.server.calculatorEndpoint, postSaving).subscribe((res) => {
                console.log(res);
                resolve(res);
            }, (error) => {
                reject();
            });
        });
    }

    postSSC(postSSC:PostSSC):Promise<Saving>{
        return new Promise((resolve, reject) => {
            console.log('Datos a enviar: ', postSSC);
            this.httpClient.post<Saving>(this.settings.server.simplifiedEndpoint, postSSC).subscribe((res) => {
                console.log(res);
                resolve(res);
            }, (error) => {
                reject();
            });
        });
    }

}
