import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {  
  public settings: Settings;
  public menuItems:Array<any>;
  constructor(public appSettings:AppSettings) {
      this.settings = this.appSettings.settings;
  }

  ngOnInit() {

  }

  public closeSubMenus(){

  }


}
