import { Injectable } from '@angular/core';
import { Response } from '../../interfaces/response.model';
import { Saving } from '../../interfaces/saving.model';

@Injectable()
export class DashboardState {
    public area = 0;
    public latitude = 0.0;
    public longitude = 0.0;
    public step = 0;

    public assessorId = '';

    public saving:Saving;

    public tipo_tarifa:Response[] = [
        {
            key: 'tipo_tarifa',
            value: '20_SIN_DISCRIMINACION_HORARIA',
            message: '2.0 sin discriminación horaria',
            image: 'assets/img/responses/answer11.svg',
            icon: 'custom-answer11'
        },{
            key: 'tipo_tarifa',
            value: '20_CON_DISCRIMINACION_HORARIA',
            message: '2.0 con discriminación horaria',
            image: 'assets/img/responses/answer12.svg',
            icon: 'custom-answer12'
        },{
            key: 'tipo_tarifa',
            value: '21_SIN_DISCRIMINACION_HORARIA',
            message: '2.1 sin discriminación horaria',
            image: 'assets/img/responses/answer13.svg',
            icon: 'custom-answer13'
        },{
            key: 'tipo_tarifa',
            value: '21_CON_DISCRIMINACION_HORARIA',
            message: '2.1 con discriminación horaria',
            image: 'assets/img/responses/answer14.svg',
            icon: 'custom-answer14'
        },{
            key: 'tipo_tarifa',
            value: '30A',
            message: '30A',
            image: 'assets/img/responses/answer15.svg',
            icon: 'custom-answer15'
        },{
            key: 'tipo_tarifa',
            value: '31A',
            message: '31A',
            image: 'assets/img/responses/answer16.svg',
            icon: 'custom-answer16'
        },{
            key: 'tipo_tarifa',
            value: '6_PERIODOS',
            message: '6 periodos',
            image: 'assets/img/responses/answer17.svg',
            icon: 'custom-answer17'
        },
    ];

    public tipo_consumidor:Response[] = [
        {
            key: 'tipo_consumidor',
            value: 'DOMICILIO',
            message: 'Domicilio',
            image: 'assets/img/responses/answer32.svg',
            icon: 'custom-answer32'
        },{
            key: 'tipo_consumidor',
            value: 'EMPRESA',
            message: 'Empresa',
            image: 'assets/img/responses/answer31.svg',
            icon: 'custom-answer31'
        },{
            key: 'tipo_consumidor',
            value: 'OFICINA',
            message: 'Oficina',
            image: 'assets/img/responses/answer34.svg',
            icon: 'custom-answer34'
        },{
            key: 'tipo_consumidor',
            value: 'INDUSTRIA',
            message: 'Industria',
            image: 'assets/img/responses/answer36.svg',
            icon: 'custom-answer36'
        },{
            key: 'tipo_consumidor',
            value: 'HOSTELERIA',
            message: 'Hostelería',
            image: 'assets/img/responses/answer35.svg',
            icon: 'custom-answer35'
        },{
            key: 'tipo_consumidor',
            value: 'COLEGIO',
            message: 'Colegio',
            image: 'assets/img/responses/answer33.svg',
            icon: 'custom-answer33'
        }
    ];
}